import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@tanstack/react-query";
import { RowSelectionState } from "@tanstack/react-table";
import { useDebounce } from "use-debounce";

import { PageContainer } from "../PageContainer";
import { TitleSection } from "../TitleSection";
import { getOffersColumns } from "./Columns";
import { DataTable } from "../DataTable/DataTable";
import { getSubarrayByPositions } from "../DataTable/utils";
import SkeletonTable from "../DataTable/SkeletonTable";
import { BatchActions } from "../table/BatchActions";
import { FilterPage } from "../table/FilterPageWithShadcn";
import { FilterQuantity } from "../table/FilterQuantityPage";
import { FilterSort } from "../table/FilterSort";
import { IFilterRequest } from "../../types/IFilter";
import OfferService from "../../services/offerService";
import { updateUrl } from "../../utils/updateUrl";
import { sortOptions } from "./constants/SortOptions";
import { actionOptions } from "./constants/ActionOptions";
import { StateContext } from "@/context/StateProvider";
import { IOffersResponse } from "@/types/IOffer";
import { Input } from "../ui/input";
import { DialogEditPrice } from "./DialogEditPrice";
import { getUrlParams } from "@/utils/get-url-params";
import { SwapSummary } from "./SwapSummary";

export const OffersView = () => {
  const navigate = useNavigate();
  const { dispatch } = useContext(StateContext);

  const { page, limit, query, sortBy, sortOrder } = getUrlParams();
  const defaultFilters = {
    limit,
    page,
    sortBy,
    sortOrder,
  };

  const [filters, setFilters] = useState<IFilterRequest>(defaultFilters);
  const [search, setSearch] = useState<string>(query);
  const [action, setAction] = useState<string>(actionOptions[0]?.value ?? "");
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [rowOnEdit, setRowOnEdit] = useState<IOffersResponse>();
  const [debouncedSearch] = useDebounce(search, 500);

  const {
    data: offersData,
    isFetching: isFetchingOffers,
    refetch: refetchOffers,
  } = useQuery({
    queryKey: ["offers", filters, debouncedSearch],
    queryFn: ({ signal }) =>
      OfferService.getAll({ ...filters, query: debouncedSearch, signal }),
  });

  const { data: summaryData, isFetching: isFetchingSummary } = useQuery({
    queryKey: ["offers", "summary"],
    queryFn: () => OfferService.getSummary(),
  });

  const [newSubtotal, setNewSubtotal] = useState<number>();
  const [newTotal, setNewTotal] = useState<number>();
  const [newService, setNewService] = useState<number>();

  const updateOfferStatus = useMutation((id: string) =>
    OfferService.update(id, { status: action })
  );

  const updateOfferPrice = useMutation(() =>
    OfferService.update(rowOnEdit!.id, {
      subtotal: newSubtotal,
      service: newService,
      total: newTotal,
    })
  );

  const removeOffer = useMutation((id: string) => OfferService.remove(id));

  const handleChangeSearch = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const newSearch = evt.target.value;
    updateUrl({ query: newSearch, page: 1 });
    setSearch(newSearch);
  };

  const onEditPrice = (id: string) => {
    setIsDialogOpen(true);
    setRowOnEdit(offersData?.rows.find((offer) => offer.id === id));
  };

  const onDelete = async (id: string) => {
    if (!window.confirm("¿Está seguro de que desea eliminar la oferta?"))
      return;

    dispatch({ type: "showLoaderScreen", payload: true });
    removeOffer
      .mutateAsync(id)
      .then((res) => {
        dispatch({ type: "showLoaderScreen", payload: false });
        dispatch({ type: "toastSuccess", payload: "Oferta eliminada." });
      })
      .catch((err) => {
        dispatch({ type: "showLoaderScreen", payload: false });
        dispatch({
          type: "toastError",
          payload: "No pudimos eliminar la oferta.",
        });
      })
      .finally(() => {
        refetchOffers();
      });
  };

  const onSeeTraceability = (order_ticket_id: string) =>
    navigate(`/admin/orderTicket/${order_ticket_id}`);

  const handleAction = async () => {
    // obtenemos los index de las filas elegidas
    const indexesSelected = Object.keys(rowSelection).map((index) => +index);

    // validamos que haya al menos un index
    if (indexesSelected.length === 0) {
      dispatch({
        type: "toastError",
        payload: "Elegí al menos una oferta",
      });
      return;
    }

    // obtenemos los id de las ofertas elegidas
    const selectedOffers = getSubarrayByPositions(
      offersData!.rows,
      indexesSelected
    );
    const selectedOffersIds = selectedOffers.map((offer) => offer.id);

    // llamamos al servicio para updatear los estados
    dispatch({ type: "showLoaderScreen", payload: true });

    const updatePromises = selectedOffersIds.map((id) =>
      updateOfferStatus.mutateAsync(id)
    );

    Promise.all(updatePromises)
      .then(() => {
        dispatch({ type: "showLoaderScreen", payload: false });
        dispatch({
          type: "toastSuccess",
          payload: "Status modificado correctamente",
        });
      })
      .catch((error) => {
        console.error("Error al modificar el estado:", error);
        dispatch({ type: "showLoaderScreen", payload: false });
        dispatch({
          type: "toastError",
          payload: "Error al modificar el estado",
        });
      })
      .finally(() => {
        setRowSelection({});
        refetchOffers();
      });
  };

  const handleCloseModal = () => {
    setNewSubtotal(undefined);
    setNewTotal(undefined);
    setNewService(undefined);
  };

  const handleEditPrice = async () => {
    dispatch({ type: "showLoaderScreen", payload: true });

    updateOfferPrice
      .mutateAsync()
      .then(() => {
        dispatch({ type: "showLoaderScreen", payload: false });
        dispatch({
          type: "toastSuccess",
          payload: "Precio modificado correctamente",
        });
      })
      .catch((error) => {
        console.error("Error al modificar el precio:", error);
        dispatch({ type: "showLoaderScreen", payload: false });
        dispatch({
          type: "toastError",
          payload: "Error al modificar el precio",
        });
      })
      .finally(() => {
        setRowOnEdit(undefined);
        setNewSubtotal(undefined);
        refetchOffers();
      });
  };

  const pageHeader = isFetchingSummary ? (
    <p className="text-center">Cargando...</p>
  ) : (
    <SwapSummary swapSummaryData={summaryData!} />
  );

  const pageContent = isFetchingOffers ? (
    <SkeletonTable columns={getOffersColumns({})} />
  ) : (
    <>
      <DataTable
        columns={getOffersColumns({
          onEditPrice,
          onDelete,
          onSeeTraceability,
        })}
        data={offersData?.rows || []}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
      />

      <div className="flex justify-between pt-10">
        <FilterPage
          filters={{ ...filters, query: debouncedSearch }}
          setFilters={setFilters}
          totalPages={offersData!.pages}
        />
        <FilterQuantity
          filters={{ ...filters, query: debouncedSearch }}
          setFilters={setFilters}
        />
      </div>

      {rowOnEdit && (
        <DialogEditPrice
          handleCloseModal={handleCloseModal}
          newSubtotal={newSubtotal}
          setNewSubtotal={setNewSubtotal}
          newTotal={newTotal}
          setNewTotal={setNewTotal}
          newService={newService}
          setNewService={setNewService}
          isDialogOpen={isDialogOpen}
          setIsDialogOpen={setIsDialogOpen}
          offer={rowOnEdit}
          handleEditPrice={handleEditPrice}
        />
      )}
    </>
  );

  return (
    <PageContainer>
      <TitleSection>Ofertas</TitleSection>
      {pageHeader}
      <div className="mt-10 flex justify-between">
        <div />
        <Input
          type="text"
          placeholder="Search..."
          value={search}
          onChange={handleChangeSearch}
        />
      </div>

      <div className="mt-5 items-center xl:flex xl:justify-between">
        <BatchActions
          setAction={setAction}
          actionOptions={actionOptions}
          handleAction={handleAction}
        />
        <FilterSort
          sortOptions={sortOptions}
          filters={{ ...filters, query: debouncedSearch }}
          setFilters={setFilters}
        />
      </div>

      {pageContent}
    </PageContainer>
  );
};
