import { useQuery } from "@tanstack/react-query";

import EventService from "../../../../services/eventService";
import { SwapSummary } from "../../../offers/SwapSummary";
import { TitleSection } from "../../../TitleSection";
import { getSwapSummaryData, groupOffersByTicket } from "./utils";
import { TicketsTable } from "./ticketsTable";

interface SwapProps {
  eventId: string;
}

export const Swap = ({ eventId }: SwapProps) => {
  const {
    data: offersData,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ["offers", eventId],
    queryFn: ({ signal }) => EventService.getEventOffers(eventId, signal),
  });

  if (isFetching) {
    return <p className="text-center">Cargando...</p>;
  }

  if (isError) {
    return <p className="text-center">Hubo un error al buscar los datos.</p>;
  }

  if (!offersData || offersData.length === 0) {
    return <p className="text-center">No hay ofertas para este evento.</p>;
  }

  const swapSummaryData = getSwapSummaryData(offersData);
  const groupedOffersByTicket = groupOffersByTicket(offersData);

  return (
    <>
      <TitleSection>Swap</TitleSection>
      <SwapSummary swapSummaryData={swapSummaryData} />
      <TicketsTable groupedOffersByTicket={groupedOffersByTicket} />
    </>
  );
};
