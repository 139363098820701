import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useMutation } from "@tanstack/react-query";
import * as yup from "yup";
import moment from "moment/moment";
import { motion } from "framer-motion";
import jsonToFormData from "@ajoelp/json-to-formdata";
import { Back } from "../../Back";
import FormInput from "../../FormElement/FormInput";
import FormRadio from "../../FormElement/FormRadio";
import FormSelectFull from "../../FormElement/FormSelectFull";
import { FilterQtyPage } from "../../table/FilterQtyPage";
import { FilterPage } from "../../table/FilterPage";
import { INotificationRequest } from "../../../types/INotification";
import { IEventResponse } from "../../../types/IEvent";
import NotificationService from "../../../services/notificationService";
import EventService from "../../../services/eventService";
import ProducerService from "../../../services/producerService";
import NewsService from "../../../services/newsService";
import ArtistService from "../../../services/artistService";
import { setFormErrorsFromServer } from "../../../utils/form";
import { StateContext } from "../../../context/StateProvider";
import FormCropperImage from "@/components/FormElement/FormCropperImage";
import FormDateTimeField from "@/components/FormElement/FormDateTimeField";

const imageValidation = yup
  .mixed()
  .test("size", "Tamaño permitido menor a 20MB", (value) => {
    if (value instanceof File) {
      return value.size <= 20000000;
    }
    return true;
  });

const imageRequired = yup
  .mixed()
  .test("required", "Campo requerido", (value) => {
    return value ? true : false;
  });


const schema = yup.object().shape({
  title: yup.string().required("Campo requerido"),
  message: yup.string().required("Campo requerido"),
  link: yup.string(),
  show_content: yup.string(),
  display_type: yup.string().required("Campo requerido"),
  expiration_date: yup.string().when('display_type', {
    is: 'popup',
    then: yup.string().required('Campo requerido'),
    otherwise: yup.string().optional(),
  }),
  popup_button_text: yup.string().when('display_type', {
    is: 'popup',
    then: yup.string().required('Campo requerido'),
    otherwise: yup.string().optional(),
  }),
  thumbnail_url: yup.mixed().when('display_type', {
    is: 'popup', 
    then: imageValidation.concat(imageRequired),
    otherwise: yup.string().optional(),
  }),
});

const defaultValues = {
  title: "",
  message: "",
  link: "",
  send_to: "all",
  event_id: "",
  action: "action_event",
  show_content: "",
  type_attend: "both",
  checked_events: [],
  display_type: "",
  expiration_date: "",
  from_admin: true,
  popup_button_text: "",
  thumbnail_url: "",
};

const receiverOptions: Array<object> = [
  { value: "all", text: "Todos los usuarios" },
  { value: "user", text: "Usuarios con ticket de un evento" },
  { value: "attend", text: "Usuarios con asistiré en un evento" },
  { value: "checkin", text: "Usuarios con checkin en un evento" },
];

const actionOptions: Array<object> = [
  { value: "action_event", text: "Mostrar evento" },
  { value: "action_artist", text: "Mostrar artista" },
  { value: "action_news", text: "Mostrar novedad" },
  { value: "action_url", text: "Mostrar url" },
];

const displayTypeOptions: Array<object> = [
  {
    value: 'push',
    text: "No silenciosa (llega push a la bandeja del teléfono)",
  },
  { value: 'silent', text: "Silenciosa (sólo llega como actividad en la app)" },
  { value: 'popup', text: "Modal Popup (se muestra modal sin pasar por actividad en la app" },
];

const columnData = ["Seleccionar evento", "Productora"];

const defaultFilters = {
  status: "all",
  sortBy: "createdAt",
  sortOrder: "-1",
  query: "",
};

const attendOptions = [
  { value: "ticket", label: "Con ticket" },
  { value: "no-ticket", label: "Sin ticket" },
  { value: "both", label: "Ambos" },
];

function compare(a: IEventResponse, b: IEventResponse) {
  if (a.name?.toLowerCase() < b.name?.toLowerCase()) {
    return -1;
  }
  if (a.name?.toLowerCase() > b.name?.toLowerCase()) {
    return 1;
  }
  return 0;
}

export const FormNotification = ({}) => {
  const navigate = useNavigate();
  const { dispatch } = useContext(StateContext);

  const [eventOptions, setEventOptions] = useState<Array<any>>([]);
  const [newOptions, setNewOptions] = useState<Array<any>>([]);
  const [artistOptions, setArtistOptions] = useState<Array<any>>([]);
  const [contentOptions, setContentOptions] = useState<Array<any>>([]);
  const [sendTo, setSendTo] = useState("all");
  const [action, setAction] = useState("");
  const [allEvents, setAllEvents] = useState<Array<IEventResponse>>([]);
  const [checkedEvents, setCheckedEvents] = useState<Array<string>>([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [search, setSearch] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [producers, setProducers] = useState<Array<any>>([]);
  const [pagination, setPagination] = useState({
    limit: 5,
    page: 1,
    total: 0,
    pages: 0,
    rows: [],
  });

  const form = useForm<INotificationRequest>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const getEvents = useMutation(() =>
    EventService.getAllSimple({ ...defaultFilters, status: "active" })
  );
  const getNews = useMutation(() =>
    NewsService.getAll({ ...defaultFilters, ...{ status: "active" } })
  );
  const getArtists = useMutation(() =>
    ArtistService.getAll({ ...defaultFilters, ...{ status: "active" } })
  );
  const createItems = useMutation((item: FormData) =>
    NotificationService.create(item)
  );
  const getProducers = useMutation(() => ProducerService.getAll({}));

  useEffect(() => {
    let formSendTo = form.watch("send_to");
    if (formSendTo == "all") {
      form.setValue("event_id", "");
    }
    setSendTo(formSendTo);

    const currentDate = moment();
    let list: any = null;
    switch (formSendTo) {
      //i need the events that ended: current_date > event.end
      case "checkin":
        list = allEvents
          .filter((ev) => currentDate > moment(ev.date_end))
          .sort(compare)
          .map((ev) => ({
            value: ev.id,
            text: ev.name,
            producer: producers.find((prod) => prod.id === ev.producer_id)
              ?.name,
          }));
        break;
      //this two cases are the same?
      case "attend":
      case "user":
      default:
        list = allEvents
          .filter((ev) => currentDate < moment(ev.date_start))
          .sort(compare)
          .map((ev) => ({
            value: ev.id,
            text: ev.name,
            producer: producers.find((prod) => prod.id === ev.producer_id)
              ?.name,
          }));
    }

    setEventOptions(list);
    setContentOptions(list);
    //now i do the "pagination"
    setPagination({
      limit: 5,
      page: 1,
      total: list.length,
      pages: (pagination.page - 1) * pagination.limit,
      rows: list.slice(0, pagination.limit),
    });
  }, [form.watch("send_to")]);

  useEffect(() => {
    loadEvents();
    loadOptions();
  }, []);

  useEffect(() => {
    let formTicket = form.watch("action");
    setAction(formTicket);
    switch (formTicket) {
      case "action_event":
        setContentOptions(eventOptions);
        setAction(formTicket);
        break;
      case "action_artist":
        setContentOptions(artistOptions);
        break;
      case "action_news":
        const news = newOptions.filter((item) => item.type == "Novedad");
        setContentOptions(news);
        break;
      case "action_url":
      default:
        setAction(formTicket);
    }
  }, [form.watch("action")]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      searcher(searchTerm);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, search]);

  const searcher = async (text: string) => {
    setSearch(text);
    if (text === "") {
      setPagination({
        limit: 5,
        page: 1,
        total: eventOptions.length,
        pages: (pagination.page - 1) * pagination.limit,
        rows: eventOptions.slice(0, pagination.limit),
      });
    } else {
      let events_search: any = [];
      eventOptions.forEach((event) => {
        if (event.text.toLowerCase().includes(text.toLowerCase())) {
          events_search.push(event);
        }
      });
      setPagination({
        limit: 5,
        page: 1,
        total: events_search.length,
        pages: (pagination.page - 1) * pagination.limit,
        rows: events_search.slice(0, pagination.limit),
      });
    }
  };

  const loadOptions = () => {
    getNews.mutateAsync().then((response) => {
      if (response) {
        const list = response.rows.map((_) => ({
          value: _.id,
          text: _.title,
          type: _.type,
        }));
        setNewOptions(list);
      }
    });
    getArtists.mutateAsync().then((response) => {
      if (response) {
        const filteredResult = response.rows
          .filter((item) => item.nickname !== "")
          .sort((a, b) => a.nickname.localeCompare(b.nickname))
          .map((item) => ({ value: item.id, text: item.nickname }));
        setArtistOptions(filteredResult);
      }
    });
  };

  const loadEvents = () => {
    dispatch({ type: "showLoaderScreen", payload: true });
    Promise.all([getProducers.mutateAsync(), getEvents.mutateAsync()])
      .then(([producersResponse, eventsResponse]) => {
        setProducers(producersResponse.rows);
        if (eventsResponse) {
          setAllEvents(eventsResponse.rows);
          const currentDate = new Date();
          const list = eventsResponse.rows
            .filter((ev) => new Date(ev.publish_date_end) > currentDate)
            .map((ev) => ({
              value: ev.id,
              text: ev.name,
              producer: producersResponse.rows.find(
                (prod) => prod.id === ev.producer_id
              )?.name,
            }));
          setEventOptions(list);
          setContentOptions(list);
          setPagination({
            limit: 5,
            page: 1,
            total: list.length,
            pages: (pagination.page - 1) * pagination.limit,
            rows: list.slice(0, pagination.limit),
          });
          dispatch({ type: "showLoaderScreen", payload: false });
        }
      })
      .catch((reason) => {
        console.log(`error ${reason}`);
        dispatch({ type: "showLoaderScreen", payload: false });
      });
  };

  const handleBack = () => {
    navigate(`/admin/notifications`);
  };

  const handleCongratulations = () => {
    navigate(`/admin/notifications/congratulations`);
  };

  const onSubmit = async (data: INotificationRequest) => {
    if (sendTo != "all") {
      if (checkedEvents.length === 0) {
        form.setError("event_id", {
          type: "custom",
          message: "Campo requerido",
        });
      }
    }
    if (
      ["action_url"].includes(action) &&
      form.watch("link").toString() === ""
    ) {
      form.setError("link", { type: "custom", message: "Campo requerido" });
    }
    if (
      !["action_url"].includes(action) &&
      form.watch("show_content").toString() === ""
    ) {
      form.setError("show_content", {
        type: "custom",
        message: "Campo requerido",
      });
    }

    data.checked_events = checkedEvents;

    let formData = jsonToFormData(data, {
      arrayIndexes: true,
      excludeNull: true,
    });

    dispatch({ type: "showLoaderScreen", payload: true });

    createItems
      .mutateAsync(formData)
      .then((res) => {
        dispatch({ type: "showLoaderScreen", payload: false });
        handleCongratulations();
      })
      .catch((reason) => {
        dispatch({ type: "showLoaderScreen", payload: false });
        setFormErrorsFromServer(reason, form.setError);
      });
  };

  const handleSelectAll = () => {
    setIsCheckAll(!isCheckAll);
    const ids = [];
    for (let index = 0; index < eventOptions.length; index++) {
      const item = eventOptions[index].value;
      ids.push(item);
    }
    setCheckedEvents(ids);
    if (isCheckAll) {
      setCheckedEvents([]);
    }
  };

  const handleClick = (e: any, idItem: string) => {
    const { checked } = e.target;
    setCheckedEvents([...checkedEvents, idItem]);
    if (!checked) {
      setCheckedEvents(checkedEvents.filter((item) => item !== idItem));
    }
    setIsCheckAll(false);
  };

  const handleVerifyAll = () => {
    return isCheckAll;
  };

  const handleVerify = (idItem: string) => {
    return checkedEvents.includes(idItem);
  };

  const onError = (error: any) => {
    console.log("error ", error);
    if (checkedEvents.length === 0) {
      form.setError("event_id", { type: "custom", message: "Campo requerido" });
    }
  };

  const handlePagination = (newPagination: any) => {
    // Calculate the start and end indices for the current page
    const startIndex = (newPagination.page - 1) * newPagination.limit;
    const endIndex = newPagination.page * newPagination.limit;
    const events = eventOptions.slice(startIndex, endIndex);
    setPagination({
      limit: newPagination.limit,
      page: newPagination.page,
      total: eventOptions.length,
      pages: (newPagination.page - 1) * newPagination.limit,
      rows: events,
    });
  };

  return (
    <div>
      <Back onClick={handleBack} />
      <div className="flex flex-col gap-10 xl:px-[23rem]">
        <h1 className="text-center text-3xl font-bold">Nuevo mensaje Push</h1>
        <FormProvider {...form}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
            className="flex flex-col gap-10"
          >
            <FormSelectFull
              name="send_to"
              options={receiverOptions}
              label="Selecciona un destinatario"
            />
            {sendTo === "attend" && (
              <div>
                <FormRadio
                  name="type_attend"
                  label="Tipo de Ticket"
                  options={attendOptions}
                  align={"start"}
                />
              </div>
            )}
            {["attend", "user", "checkin"].includes(sendTo) && (
              <div>
                <table className="my-table-spacing h-full w-full border-separate border-spacing-y-2 gap-5 overflow-x-auto text-left">
                  <thead className=" divide-solid border ">
                    <tr className=" pb-4 text-sm font-bold text-gray-100">
                      <th className="border-b-[1px] border-colorBorder pb-4">
                        <input
                          id="checkbox-all"
                          onClick={handleSelectAll}
                          type="checkbox"
                          checked={handleVerifyAll()}
                          className="text-blue-600 rounded border-gray-200 focus:ring-blue-500"
                          onChange={() => {}}
                        />
                      </th>
                      {columnData.map((column, index) => (
                        <th
                          className="border-b-[1px] border-colorBorder pb-4"
                          key={column + index}
                        >
                          {column}
                        </th>
                      ))}
                      <th className="border-b-[1px] border-colorBorder pb-4"></th>

                      <th className="flex justify-end border-b-[1px] border-colorBorder pb-4">
                        <input
                          type="text"
                          placeholder="Search..."
                          className="hover:text-black rounded-full border-[2px] border-black-100 px-4 py-1 font-bold  text-black-100"
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {pagination.rows.length !== 0 ? (
                      pagination.rows.map((item, i) => (
                        <motion.tr
                          key={i}
                          layout
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          initial={{ opacity: 0.2 }}
                          transition={{
                            type: "spring",
                            stiffness: 100,
                            bounce: 0,
                          }}
                          className={`h-16  rounded-xl text-start text-sm font-medium outline outline-[1px] outline-colorBorder`}
                        >
                          <td className="pl-4">
                            <input
                              type="checkbox"
                              onClick={(e) => handleClick(e, item.value)}
                              checked={handleVerify(item.value)}
                              className="text-blue-600 rounded border-gray-200 focus:ring-blue-500"
                              onChange={() => {}}
                            />
                          </td>
                          <td className="capitalize">{item.text}</td>
                          <td className="pl-4 capitalize">{item.producer}</td>
                        </motion.tr>
                      ))
                    ) : (
                      <p className="text-center">
                        No existen eventos disponibles
                      </p>
                    )}
                  </tbody>
                </table>
                <div className="flex justify-between pt-10">
                  <FilterPage
                    handleFilter={handlePagination}
                    total={pagination.total}
                    limit={pagination.limit}
                    currentPage={pagination.page}
                  />
                  <FilterQtyPage handleFilter={handlePagination} />
                </div>
              </div>
            )}
            <FormInput name="title" type="text" label="Título del mensaje" />
            <FormInput name="message" type="text" label="Mensaje" />
            <FormSelectFull
              name="action"
              options={actionOptions}
              label="Definir una acción en la aplicación"
            />
            {["action_url"].includes(action) && (
              <FormInput name="link" type="tex" label="Url a enviar" />
            )}

            {!["action_url"].includes(action) && (
              <FormSelectFull
                name="show_content"
                options={[
                  { text: `Seleccionar contenido...`, value: "" },
                ].concat(contentOptions)}
                label="Seleccionar el contenido a mostrar en el app"
              />
            )}

            <FormSelectFull
              name="display_type"
              options={displayTypeOptions}
              label="Seleccionar el tipo de notificación"
            />
            {form.watch("display_type").toString() === 'popup' && (
              <>
              <FormInput name="popup_button_text" type="text" label="Texto en botón de acción" />
              <FormDateTimeField
                  name="expiration_date"
                  type="date"
                  label="Fecha de vencimiento del popup"
                />
              <div className="container mx-auto max-w-sm py-8 flex flex-col items-center justify-center">
              <FormCropperImage
                name="thumbnail_url"
                label="Imagen en modal de popup"
                accept="image/png, image/gif, image/jpeg"
                initialAspectRatio={1.77}
                cropBoxResizable={false}
                dragMode="move"
              />
              <div className="text-center mt-4">
                <p className="text-xl font-bold text-gray-800 mt-2">{form.watch("title").toString()}</p>
                <p className="text-sm text-gray-500 mt-2">{form.watch("message").toString()}</p>
                <button className="mt-4 rounded-full border-[2px] border-black-100 bg-black-100 px-[5.4rem] py-2 font-medium  text-white">
                  {form.watch("popup_button_text")}
                </button>
              </div>
            </div>
              </>
            )}
          </form>
        </FormProvider>
        <div className="mb-16 flex justify-center gap-10 pt-10">
          <button
            onClick={handleBack}
            type="button"
            className="rounded-full border-[2px] border-black-100 px-12 py-2 font-medium text-black-100 "
          >
            Cancelar
          </button>
          <button
            onClick={form.handleSubmit(onSubmit, onError)}
            className="rounded-full border-[2px] border-black-100 bg-black-100 px-[5.4rem] py-2 font-medium  text-white"
          >
            Enviar Ahora
          </button>
        </div>
      </div>
    </div>
  );
};
